<template>
  <div class="card-body rounded" v-background-3>
    <h5 class="card-title d-flex justify-content-between align-items-center">
      <div>{{ rex.affaire_identifiant }}</div>
      <UserViewer :userId="rex.affaire_pilote_id" v-if="rex.affaire_pilote_id" :show-text="false"></UserViewer>
    </h5>
    <h6 class="card-subtitle mb-2 text-muted"></h6>
    <p class="card-text">
      {{ rex.affaire_rex_synthese }}
      <span v-for="tag in tags" :key="tag" class="badge badge-sm badge-primary mr-1 mb-1 text-wrap">
        {{ tag }}
      </span>
    </p>
  </div>
</template>
<script>
import UserViewer from "@/components/application/UserViewer.vue";

export default {
  props: ["rex"],
  components: { UserViewer },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
  mounted() {},
  computed: {
    tags() {
      if (this.rex.affaire_rex_tags == "" || this.rex.affaire_rex_tags == null) return "";
      return this.rex.affaire_rex_tags.split(";");
    },
  },
};
</script>
<style></style>
