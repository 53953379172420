<template>
  <div class="w-100">
    <h1>Retour d'expérience</h1>
    <div v-if="loading">Chargement...</div>
    <div
      class="rounded shadow-sm p-3 mb-3 d-flex align-items-center justify-content-between"
      v-background-3
      v-if="!loading"
    >
      <div>{{ data.length }} retours enregistrés</div>
      <input-search
        text="Rechercher..."
        v-model="searchText"
        @reset="searchReset"
        :showResults="false"
        :loading="loading"
        :modeIOS="true"
      ></input-search>
    </div>
    <div class="card-columns" v-if="!loading">
      <div class="card shadow-sm border-0 rounded" style="width: 100%" v-for="rex in rexFiltered" :key="rex.id">
        <rex-item :rex="rex"></rex-item>
      </div>
    </div>
  </div>
</template>
<script>
import RexItem from "@/components/affaires/DashboardRexItem.vue";
import InputSearch from "@/components/bases/InputSearch.vue";

export default {
  components: { RexItem, InputSearch },
  data() {
    return {
      loading: false,
      data: [],
      searchText: "",
    };
  },
  methods: {
    getRex() {
      this.loading = true;
      this.$http
        .get("affaires/rex")
        .then((resp) => {
          this.data = resp.data;
        })
        .finally(() => (this.loading = false));
    },
    searchReset: function () {
      this.searchText = "";
    },
  },
  mounted() {
    this.getRex();
  },
  computed: {
    rexFiltered() {
      let rep = this.data;
      if (this.searchText !== "") {
        rep = rep.filter((affaire) => {
          let identifiant = "";
          let synthese = "";
          let affaire_rex_tags = "";

          if (affaire.affaire_identifiant) {
            identifiant = affaire.affaire_identifiant;
          }

          if (affaire.affaire_rex_synthese) {
            synthese = affaire.affaire_rex_synthese;
          }

          if (affaire.affaire_libelle) {
            affaire_rex_tags = affaire.affaire_rex_tags;
          }

          return (
            identifiant.toLowerCase().includes(this.searchText.toLowerCase()) ||
            synthese.toLowerCase().includes(this.searchText.toLowerCase()) ||
            affaire_rex_tags.toLowerCase().includes(this.searchText.toLowerCase())
          );
        });
      }

      return rep.slice().reverse();
    },
  },
};
</script>
<style></style>
